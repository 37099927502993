.logoSpan {
    color: var(--color-indigo);
  }
  .yourLogoDiv {
    position: relative;
    font-weight: 600;
    display: inline-block;
  }
  .mainLogoImg{
    /* width: 150px; */
    height: auto; 
  }
  .logoA {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    padding: 10px;
    padding-bottom: 0;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 60px;
    color: inherit;
  }
  .userNameTextField {
    border: 0;
    background-color: transparent;
  }
  .forgotPwdA {
    text-decoration: none;
    cursor: pointer;
  }
  .forgotPassword,
  .termsOfUse {
    text-decoration: none;
    position: relative;
    display: inline-block;
  }
  .forgotPassword {
    color: var(--color-indigo);
  }
  .termsOfUse {
    color: inherit;
  }
  .bottomLinksDiv,
  .main {
    display: flex;
    justify-content: center;
  }
  .bottomLinksDiv {
    flex-direction: row;
    align-items: flex-end;
    gap: 109px;
    margin-top: 5rem;
    color: var(--color-gray-100);
  }
  .main {
    position: absolute;
    top: calc(50% - 400px);
    left: calc(50% - 720px);
    background-color: #f3f3f3;
    width: 1439px;
    flex-direction: column;
    padding: 60px;
    box-sizing: border-box;
    align-items: center;
    gap: 40px;
    text-align: left;
    font-size: var(--font-size-base);
    color: #000;
    font-family: var(--font-inter);
  }
  .loginDiv {
    position: relative;
    background-color: #f6f6f6;
    width: 100%;
    height: 800px;
    overflow: hidden;
  }
  .field{
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .muibtn{
    width: 100%;
    height: 45px;
  }

  .margin button {
    margin-top: 10px;
    width:100%;
 }

  
  