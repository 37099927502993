.heading{
    text-align: left;
    display: flex;
    justify-content: center;
}
.container{
  /* display: flex; */
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  margin-top: 60px;
}

.containerr{
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  margin-top: 66px;
}

.img{
  display: flex;
  justify-content: center;
}

.image{
  display: flex;
  justify-content: center;
  margin-left: 60px;
}

.button1 {
    background-color: #18385E;
    color: #fff;
    border: 1px solid #18385E;
    font-size: 18px;
    font-weight: 400;
    padding: 10px;
    border-radius: 8px;
    margin-top: 20px;
}
.button2{
    margin-top: 88px;
    background-color: #18385E;
    color: #fff;
    border: 1px solid #18385E;
    font-size: 18px;
    font-weight: 400;
    padding: 10px;
    border-radius: 8px;
}
.button1:hover {
background-color: #18385E;
color: #fff;
border: 1px solid #18385E;
font-size: 18px;
font-weight: 400;
padding: 10px;
border-radius: 8px;
}

.button2:hover {
  background-color: #18385E;
  color: #fff;
  border: 1px solid #18385E;
  font-size: 18px;
  font-weight: 400;
  padding: 10px;
  border-radius: 8px;
  }
.logoSpan {
    color: var(--color-indigo);
  }
  .yourLogoDiv {
    position: relative;
    font-weight: 600;
    display: inline-block;
  }
  .logoA {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    padding: 10px;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 60px;
    color: inherit;
  }
  .userNameTextField {
    border: 0;
    background-color: transparent;
    width: 100%;
    height: 2.5em !important;
  }

  .password {
    border: 0;
    background-color: transparent;
    width: 100%;
    height: 2.5em !important;
  }
  .forgotPwdA {
    text-decoration: none;
    cursor: pointer;
  }
  .forgotPassword,
  .termsOfUse {
    text-decoration: none;
    position: relative;
    display: inline-block;
  }
  .forgotPassword {
    color: var(--color-indigo);
  }
  .termsOfUse {
    color: inherit;
  }
  .bottomLinksDiv,
  .main {
    /* margin-top: 2rem; */
    display: flex;
    justify-content: center;
  }
  .bottomLinksDiv {
    flex-direction: row;
    align-items: flex-end;
    gap: 109px;
    color: var(--color-gray-100);
  }
  
  .main {
    /* position: relative; */
    /* top: calc(50% - 450px); */
    /* left: calc(50% - 720px); */
    /* background-color: #fff; */
    /* width: 1439px;
    height: 1024px; */
    overflow: hidden;
    flex-direction: column;
    /* padding: 150px; */
    box-sizing: border-box;
    align-items: center;
    gap: 40px;
    text-align: left;
    font-size: var(--font-size-base);
    color: #000;
    font-family: var(--font-inter);
  }
  .loginDiv {
    position: relative;
    background-color: #f6f6f6;
    /* width: 100%;
    height: 800px;
    overflow: hidden; */
  }
  
  .tableDiv{
    width: 100%;
    padding: 20px 0px;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 20px 0px #ccc;

    border-radius: 16px;
    /* background-color: #18385E; */
    /* padding: 10px; */
    /* border: 2px solid red; */
  }

  .grid{

    display: grid;
    gap: 20px;
  }

  .formDiv{
    width: 500px;
    margin: 0px auto;
    margin-bottom: 100px;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0px 0px 20px 0px #ccc;
    border-radius: 12px;

  }

  .titlediv{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 16px;
    margin-top: 10px;
  }
  .profileDiv{
    display: flex;
    width: 100%;
    cursor: pointer;
    /* position: relative; */

  }
  .titlea{
    display: flex;
    justify-content: right;
    align-items: center;
    width: 100%;
    font-size: 16px;
    cursor: pointer;
  }

  .title{
    width: 30%;
    display: flex;
    /* justify-content: center; */
  }


  .margin button {
     margin-top: 10px;
     width:100%;
  }
  
  .flex{
    display: flex;
  }
  

  .profileFrom{
    display: grid;
    gap: 20px;
  }



  .profilePic{
    width:100px;
    height: 100px;
    border-radius: 50%;
    cursor: pointer;
  }

  .plusIcon{
    width: 20px;
    height: 20px;
    background-color: #18385e;
    color: #fff;
    border: none;
    border-radius: 50%;
    position: absolute;
    bottom: 28px;
    right: 9px;
    font-size: 16px;
    z-index: 0;
    cursor: pointer;
    text-align: center;
  }

  .profileLabel{
    position: relative;
    z-index: 20;
  }