.logoSpan {
  color: var(--color-indigo);
}
.yourLogoDiv {
  position: relative;
  font-weight: 600;
  display: inline-block;
}
.logoA {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  padding: 10px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 60px;
  color: inherit;
}
.userNameTextField {
  border: 0;
  background-color: transparent;
}
.forgotPwdA {
  text-decoration: none;
  cursor: pointer;
}
.forgotPassword,
.termsOfUse {
  text-decoration: none;
  position: relative;
  display: inline-block;
}
.forgotPassword {
  color: var(--color-indigo);
}
.termsOfUse {
  color: inherit;
}
.bottomLinksDiv,
.main {
  display: flex;
  justify-content: center;
}
.bottomLinksDiv {
  flex-direction: row;
  align-items: flex-end;
  gap: 109px;
  margin-top: 5rem;
  color: var(--color-gray-100);
}

.main {
  position: relative;
  top: calc(50% - 420px);
  left: calc(50% - 720px);
  background-color: #fff;
  width: 1439px;
  overflow: hidden;
  flex-direction: column;
  padding: 60px;
  box-sizing: border-box;
  align-items: center;
  gap: 40px;
  text-align: left;
  font-size: var(--font-size-base);
  color: #000;
  font-family: var(--font-inter);
}
.loginDiv {
  position: relative;
  background-color: #f6f6f6;
  width: 100%;
  overflow: hidden;
}

.mainLogoImg{
  /* width: 100%; */
  height: auto;   
}
