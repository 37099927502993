.heading{
    text-align: left;
    /* margin-top: 5rem; */
}
.mainLogoImg{
  width: 150px;
  height: auto;
  margin-top: 3rem;   
}
.container{
  /* display: flex; */
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}
.button {
    background-color: #18385E;
    color: #fff;
    border: 1px solid #18385E;
    font-size: 18px;
    font-weight: 400;
    padding: 10px;
    border-radius: 8px;
}
.button:hover {
background-color: #18385E;
color: #fff;
border: 1px solid #18385E;
font-size: 18px;
font-weight: 400;
padding: 10px;
border-radius: 8px;
}
.logoSpan {
    color: var(--color-indigo);
  }
  .yourLogoDiv {
    position: relative;
    font-weight: 600;
    display: inline-block;
  }
  .logoA {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    padding: 10px;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 60px;
    color: inherit;
  }
  .userNameTextField {
    border: 0;
    background-color: transparent;
  }
  .forgotPwdA {
    text-decoration: none;
    cursor: pointer;
  }
  .forgotPassword,
  .termsOfUse {
    text-decoration: none;
    position: relative;
    display: inline-block;
  }
  .forgotPassword {
    color: var(--color-indigo);
  }
  .termsOfUse {
    color: inherit;
  }
  .bottomLinksDiv,
  .main {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
  }
  .bottomLinksDiv {
    flex-direction: row;
    align-items: flex-end;
    gap: 109px;
    color: var(--color-gray-100);
  }
  
  .main {
    /* position: relative; */
    /* top: calc(50% - 450px); */
    /* left: calc(50% - 720px); */
    background-color: #fff;
    /* width: 1439px;
    height: 1024px; */
    overflow: hidden;
    flex-direction: column;
    /* padding: 150px; */
    box-sizing: border-box;
    align-items: center;
    gap: 4px;
    text-align: left;
    font-size: var(--font-size-base);
    color: #000;
    font-family: var(--font-inter);
  }
  .loginDiv {
    position: relative;
    background-color: #f6f6f6;
    /* width: 100%;
    height: 800px;
    overflow: hidden; */
  }
  
  .tableDiv{
    width: 100%;
    padding: 20px 0px;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 20px 0px #ccc;

    border-radius: 16px;
    /* background-color: #18385E; */
    /* padding: 10px; */
    /* border: 2px solid red; */
  }

  .grid{

    display: grid;
    gap: 20px;
  }

  .formDiv{
    width: 500px;
    margin: 0px auto;
    margin-bottom: 100px;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0px 0px 20px 0px #ccc;
    border-radius: 12px;
  }