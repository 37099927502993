.ant-nav-wrapper{
    height: 70px;
    display: flex;
    justify-content: space-between;
    /* box-shadow: 5px 10px #888888; */
    padding: 0.5rem 3.5rem;
    border-bottom: 2px solid #f1f1f1;
    position: fixed;
    width: 100%;
    background-color: #fff;
    z-index: 100;
	top: 0px;
    
    /* left: 10px; */
    /* right: 50px; */
}
.ant-nav-left{
    flex: 1;
    align-items: center;
    display: flex;
}
/* .ant-nav-logo img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    
} */

.ant-nav-logo .main-logo-img1{
    width: 110px;
    height: auto;   
}

.ant-nav-right {
    display: flex;
    flex: 1.5;
    align-items: center;
    justify-content: center;
    font-weight: 400;    
}
.ant-nav-list {
    margin-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 10;
}
.ant-nav-list>ul {
    display: flex;
    gap: 2.5rem;
    margin-right: 2rem;
    list-style-type: none;
    
}
.ant-nav-list>ul .menu > li {
    font-size: 16px;
    font-weight: 400;
}
.ant-nav-list>ul .menu > li:hover{
    cursor: pointer;
    color: var(--orange);
}

.ant-nav-right .button {
    flex:2;
    font-size: 13px;
    font-weight: 600;
    padding: 10px;
    border-radius: 34px;
    background-color: transparent;
    border: 2px solid var(--orange);
    cursor: pointer;
}
.ant-nav-right .button:hover {
    background-color: var(--orange);
    color: #fff;
}

.menu{
    text-decoration: none;
    cursor: pointer;
    color: #2B2B2B;
}