.single-project{
    max-width: 92%;
    margin: 0px auto;
    margin-top: 80px;
    .section-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
.section-title{
    margin: 10px 0px 5px 0px;

}


    button{
        background-color: transparent;
        padding: 5px 15px;
        border-radius: 6px;
        color: #fff;
        border: none;
        font-weight: 500;
        cursor: pointer;
    }
    .export-btn{
        background-color: #18385D;
        width: 178px;
        font-size: 18px;
        height: 50px;
    }
    .search-box{
        width: 100%;
        background-color: #fff;
        display: flex;
        align-items: center;
        padding: 0px 10px;
        margin: 10px 0px;
        .search-img{
            width: 25px;
        }
        .search-input{
            width: 98%;
            height: 42px;
            border: none;
            outline: none;
            padding-left: 10px;
            :focus{

                outline: none;
            }
        }
    }
    .client-name{
        color: #28B2FF;
    }


    .flex-box{
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .box{
            flex: 1 0 21%; /* explanation below */
            margin: 5px;
            height: 100px;
            background: #FFFFFF;
            border-radius: 10px;
            padding: 0px 15px;
            cursor: pointer;

            

            .foot-box{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .link{
                    color:  blue;
                }
                button{
                    background-color: transparent;
                    padding: 5px 15px;
                    width: 100px;
                    border-radius: 16px;
                    color: #fff;
                    border: none;
                    font-weight: 500;
                    cursor: pointer;
                }
                .Exportad{
                    background-color: #1BBF00;
                }
                .New{
                    background-color: #006FBF;
                }
            }
        }
    }
}