.welcomeText {
  color: #288BFF;
  font-size: 20px;
  margin-bottom: 0;
}
.userName {
  color:#2B2B2B;
  font-size: 28px;
  font-weight: 600;
}
.mainLogoImg{
  /* width: 150px; */
  height: auto; 
}
.button {
  background-color: #18385E;
  color: #fff;
  border: 1px solid #18385E;
  font-size: 18px;
  font-weight: 400;
  padding: 10px;
  border-radius: 8px;
  text-decoration: none;
}
.button:hover{
  background-color: #fff;
  color: #18385E;
  font-size: 18px;
  padding: 10px;
  border-radius: 8px;
  font-weight: 400;
  border: 1px solid #18385E !important;
}
.logoSpan {
    color: var(--color-indigo);
  }
  .yourLogoDiv {
    position: relative;
    font-weight: 600;
    display: inline-block;
  }
  .logoA {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    padding: 10px;
    padding-bottom: 0;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 60px;
    color: inherit;
  }
  .userNameTextField {
    border: 0;
    background-color: transparent;
  }
  .forgotPwdA {
    text-decoration: none;
    cursor: pointer;
  }
  .forgotPassword,
  .termsOfUse {
    text-decoration: none;
    position: relative;
    display: inline-block;
  }
  .forgotPassword {
    color: var(--color-indigo);
  }
  .termsOfUse {
    color: inherit;
  }
  .bottomLinksDiv,
  .main {
    /* margin-top: 10rem; */
    display: flex;
    justify-content: center;
  }
  .bottomLinksDiv {
    flex-direction: row;
    align-items: flex-end;
    gap: 109px;
    color: var(--color-gray-100);
  }
  .main {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;

    flex-direction: column;

    box-sizing: border-box;

    /* position: absolute;
    top: calc(50% - 512px);
    left: calc(50% - 720px);
    background-color: #f3f3f3;
    width: 1439px;
    height: 1024px;
    flex-direction: column;
    padding: 150px;
    box-sizing: border-box;
    align-items: center;
    gap: 15px;
    text-align: left;
    font-size: var(--font-size-base);
    color: #000;
    font-family: var(--font-inter); */
  }
  .loginDiv {
    position: relative;
    background-color: #f6f6f6;
    width: 100%;
    height: 85vh;
    overflow: hidden;
  }
  
  .DashboardContainer{
    margin-top: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cardDesign{
    margin-top: 5rem;
    display: flex;
    justify-content: space-around;
  }
  .statesDiv {
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
  }