@import-normalize;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body, #root {
  margin: 0;
  line-height: normal;
  background-color: #f4f4f4;
  width: 100%;
  overflow-x: hidden;
}
:root {
  /* fonts */
  --font-inter: Inter;

  /* font sizes */
  --font-size-base: 20px;

  /* Colors */
  --color-gray-100: #7e7e7e;
  --color-indigo: #0a6cff;
}



.table-div{
  max-height: 300px;
  overflow-y: auto;
}

.d-flex{
  display: flex;
  gap: 2px;
}

.table-header{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  color: #2B2B2B;
  font-size: 25px;
  font-weight: 800;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}
.right-side{
  text-align: right;
  justify-content: right;
}
.edit-icons{

  gap: 20px;
}
.pointer {
  cursor:pointer;
}


.drapdown-position{
  position: absolute;
  bottom: 130px !important;
}

.table-div .table{
  width: 100%;
  /* border: 1px solid; */
  /* padding: 0px 20px; */
  margin: 10px 0px;
}

.table-title p{
  margin: 10px 0px 0px 0px;
  font-size: 18px;
  font-weight: 500;
}
.table-title small{
  font-size: 12px;
  color: #9F9F9F;
  font-weight: 400;

}

.position-fix-drop {
  top: 65px !important;
    left: -30px;
}

.table-div .table {
  background-color: #F4F4F4;
  max-height: 400px;
  overflow-y: hidden;
}

.table thead tr td{
  padding: 10px 20px;
  font-weight: 600;
} 
.table tr td{
  padding: 0px 20px;
  padding-bottom: 10px;
  font-size: 16px;
  /* text-align: center; */
  border:none;
  /* background: red; */
}

.table tr:nth-child(odd) {background: #F4F4F4}


.table-footer{
  padding: 10px 0px;
  display: flex;
  justify-content: center;
}

.success-msg{
  color: green;
  font-weight: 600;
  font-size: 16px;
  padding: 15px 20px;
  background: #22ff003d;
  border-radius: 12px;
}
.error-msg{
  color: red;
  font-weight: 600;
  font-size: 16px;
  padding: 15px 20px;
  background: #ff00003d;
  border-radius: 12px;
}

#login-form{
  display: grid;
  gap: 30px;
}

.dx-datagrid-table{
  width: 100%;
  font-size: 16px;
}


.dx-datagrid-headers table tr td .dx-datagrid-text-content{
  padding: 10px 0px;
  font-weight: 600;
} 
.dx-datagrid-table tr td{
  padding: 0px 20px;
  padding-bottom: 10px;
  font-size: 16px;
  /* text-align: center; */
  border:none;
  /* background: red; */
}

.dx-datagrid-table tr:nth-child(odd) {background: #F4F4F4}


.select-box{
  width: 100%;
  height: 40px;
}
.Edit-Form{
  gap: 20px;
  display: grid;
}
.btn-primary{
  background-color: #0a6cff;
  padding: 10px 20px;
  margin: 0px auto;
  color: #fff;
  margin: 10px 0px;
}
.btn-primary:hover{
  background-color: #3065b5;
  /* padding: 10px 20px;
  margin: 0px auto;
  color: #fff;
  margin: 10px 0px; */
}

#save-srno-form{
  display: grid;
    gap: 20px;
    width: 400px;
}

.container{
  width: 92%;
  margin: 0px auto;
  margin-top: 73px;
}


.export-btn{
  float: right;
  width: 178px;
  height: 50px;
  background: #18385D;
  border-radius: 10px;
  color: #fff;
}

.td-main{
  width: 100%;
  margin-top: 40px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px #ccc;
  padding: 10px 0px;
  border-radius: 12px;
}
.dx-datagrid{
  width: 100%;
}

.a-tag,.export-btn{
  cursor: pointer;

}

.desable{
  cursor: not-allowed;
  background: #3c536d;
}

.create-project,.create-project > button{
  cursor: pointer;
  text-decoration: none !important; 
}
.create-project > button:hover{
  cursor: pointer;
  text-decoration: none !important;
  border: none;
}

#profileImg{
  display: none;
}


.navbar-profile{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.section-title{
  margin-top: 100px;
}

.tip-box{
  color: #252424;
  font-size: 12px;
  line-height: 1;
}

.table-section-div{
  /* width: 90%; */
  margin: 0px auto;
  /* min-height: 100vh; */
}

.main-logo-img2{
  width: 150px;
  height: auto;
  padding: 10px;
}

.li-a{
  width: 100%;
  text-decoration: none;
  color: #212121;
}

.sidebar-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.slider-card{
  display: flex;
  margin-right: 20px;
}

header.MuiPaper-root{
  background: #fff;
  box-shadow: 0px 0px 10px 0px #ccc;
}

.main-content{
  max-width: 100%;
  overflow-x: hidden;
}

.main-content main{
  padding: 20px 40px;
}

.site-footer{
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-direction: row;
  align-items: flex-end;
  gap: 109px;
  color: var(--color-gray-100);
  margin-top: 10px;
  background-color: #fff;
  padding: 10px 5px;;
}
.site-footer div{
  width: 100%;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.site-footer a{
  color: var(--color-gray-100);
  font-size: 20px;
}

.content-div{
  min-height: 72vh;
  background-color: #fff;
  padding: 20px 10px;
  margin-top: 55px;
}


.form-brand{
  width: 150px;
}

.MuiPaper-root {
  min-height: 127px;
}


.qus-img{
  width: 100px;
  height: 100px;
}
.MuiToolbar-root{
  padding: 0px;
}
.navbar-profile{
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.right-side{
  width: 100%;
  background: #fff;
  height: 120px;
}
.drapdown-style{
  float: right;
}


.image-gallery{
  display: flex;
  gap: 10px;
}

.qus-img{
  cursor: pointer;
}

.modal-header{
  justify-content: space-between;
  align-items: center;
}


/* dashboard design start */
.dashboard-anlytics .text-dv{
  text-align: right;
  width: 100%;
}
.dashboard-anlytics .count{
  font-size: 40px;
}
.dashboard-anlytics .text{
  font-size: 1rem;
}
.dashboard-anlytics .text, .dashboard-anlytics .count {
  color: #fff;
  
}
.dashboard-anlytics{
  display: flex;
  width: 100%;  
  justify-content: space-between;
  padding: 0px 20px;
}
.one{
  background-color: #7DA0FA;  
}

.two{
  background-color: #4747A1;  
}

.three{
  background-color: #F3797E;  
}
.dashboard-card{
  width: 350px;
  padding: 12px;
  border-radius: 12px;
  display: flex;
  align-items: center;
}
.icon-div .icon{
  font-size: 50px;
  color: #fff;
}
/* dashboard design end */




.downloader{
  position: fixed;
  bottom: 10px;
  left: 20px;
  
}