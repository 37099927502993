body , html{
    background-color: #f4f4f4;
}
.all-projects{
    max-width: 92%;
    margin: 0px auto;
    margin-top: 80px;
    // background-color: red;    

    .flex-box{
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .box{
            flex: 1 0 21%; /* explanation below */
            margin: 5px;
            height: 100px;
            background: #FFFFFF;
            border-radius: 10px;
            padding: 0px 15px;
            cursor: pointer;

            .foot-box{
                display: flex;
                justify-content: space-between;
                align-items: center;
                button{
                    background-color: transparent;
                    padding: 5px 15px;
                    width: 100px;
                    border-radius: 16px;
                    color: #fff;
                    border: none;
                    font-weight: 500;
                    cursor: pointer;
                }
                .Exportad{
                    background-color: #1BBF00;
                }
                .New{
                    background-color: #006FBF;
                }
            }
        }
    }

    .section-ttile{
        color: #2B2B2B;
        font-size: 25px;
        top: 200px;
    }


}